import React,{useEffect, useState} from 'react'
import { Link/*, Script, ScriptStrategy*/, graphql } from "gatsby"

import axios from 'axios';
import Layout from "./../../components/layout"
import ThemeContext from "./../../context/ThemeContext"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import useWindowSize from './../../hooks/useGatsbyWindowSize'
import { useMediaQuery } from 'react-responsive'
import SwiperCore, { Virtual, Navigation, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide, useSwiperSlide  } from 'swiper/react'
import {gsap} from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { CustomEase } from "gsap/CustomEase"
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import SvgEl from './../../components/SvgEl'
import LOGO from './../../svg/logos'

SwiperCore.use([Virtual, Navigation, Pagination, Autoplay]);


gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);
gsap.config({autoKillThreshold: 1});


const WeddingsPage = ({ data }) => {

  //console.log(data.photos.edges);

  const [jsonContent, setJsonContent] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [swiperRef, setSwiperRef] = useState(null);

  const mobile = useMediaQuery({ query: `(max-width: 767px)` });
  const {width,height} = useWindowSize();
    var device = '';

    const swiperSlide = useSwiperSlide();

  // Create array with slides
  const [slides, setSlides] = useState(
    Array.from({ length: 1 }).map((_, index) =>  <div className="firstSlide"></div>)
  );

  function loadHtml(url) {
  var _json = url;
  axios.get(_json)
.then(function (response) {

  var _jsonContent = response.data.gallery;
  device = response.data.device;
 var newArr = _jsonContent.map(firstSlides);
 //console.log(newArr);
 var t= setTimeout(function(){
   setSlides(newArr);
   clearTimeout(t);
 },1200);

  var _jsonContent =  response.data.content;
  setJsonContent(_jsonContent);
  setIsLoaded(true);
});
}



function firstSlides(img){

  var css_pos = '50% 0px';
  var _params = ['#',50];
  var url = '/suites' ;
  var btnLabel = 'DISCOVER OUR SUITES';

  if(img.desc && mobile){

     _params = img.desc.split('#');
     //console.log(_params);
    if (_params.length > 2){
       css_pos =  _params[1] + '% 0px';
       url = _params[3] ? _params[3] : url;
       btnLabel = _params[2] ? _params[2] : btnLabel;
    }

  }

   return (
     <div className="slider-image-full" data-pos={_params[1] ? _params[1] : 50} style={{background:'url('+img.url+')',backgroundPosition:css_pos}}>
     <div className="slide-logo">
     <SvgEl svg={LOGO.MAIN} className='logo-bg animate__animated animate__zoomFadeIn animate__delay-3s'/>
     </div>
     <div className="featured animate__animated animate__fadeInRight animate__delay-1s">
  {/*  <h3 className="ttl">Title</h3> */}
  {_params.length > 2 ? <a href={url} className="actionBtn btn btn-dark">{btnLabel}</a> : ''}

     </div>

     </div>

  );
}

function slideEffect(){



};



  useEffect(() => {
    setIsMobile(mobile);



     loadHtml('https://discover.lilleymansion.com/app/v1/page/13/private-parties');
  },[]);


  return (

    <ThemeContext.Consumer>
        {theme => (
    <div id="page">

    <Swiper
    className="swiper-full swiper-dark"
    autoplay={{
          delay: 5500,
          disableOnInteraction: true,
        }}
    onSwiper={(e)=>{
      setSwiperRef(e);
    }}
    onSlideChange={(swiper) =>  {
      var slds = swiper.slides;
      //console.log(slds);
      var sldsActiveChild = slds[1].children[0];
      //var featuredActive =  slds[0].children[0].firstChild;
      var sldsPrevChild = slds[0].children[0];
      //var featuredPrev =  slds[1].children[0].firstChild;

      if(slds.length < 3){
        //gsap.to([featuredPrev,featuredActive],0,{delay: 0, ease: 'Quad.easeOut', opacity: 0, bottom: '-600px' });
      gsap.to([sldsActiveChild],0,{delay: 0, ease: 'Quad.easeOut', backgroundPosition: '40% 0px' });

      //  var _pos = sldsActiveChild.dataset.pos > 0 ? sldsActiveChild.dataset.pos : 50;
        gsap.to([sldsActiveChild],2,{delay: 0, ease: 'Quad.easeOut', backgroundPosition: 50 + '% 0px' });
        //gsap.to([featuredPrev,featuredActive],.3,{delay: 0, ease: 'Quad.easeOut', opacity: 1, bottom: '100px' });

      }else if(slds.length > 2){

        var sldsPrevChild = slds[0].children[0];
        var sldsNextChild = slds[2].children[0];


      //    sldsNextChild.firstChild.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
      //  var featuredPrev =  slds[0].children[0].firstChild;
      //  var featuredNext =  slds[2].children[0].firstChild;

        //gsap.to([featuredPrev,featuredNext],0,{delay: 0, ease: 'Quad.easeOut', opacity: 0, bottom: '-600px' });
    }


      //console.log(swiper.touches.diff);
      //console.log(swiper.activeIndex > swiper.previousIndex);
      //var right = swiper.activeIndex > swiper.previousIndex;
      if(slds.length > 2){

      if(swiper.activeIndex > swiper.previousIndex){ // to the right
        sldsActiveChild.firstChild.classList.remove('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
        sldsNextChild.firstChild.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');


        var num_0 = parseInt(sldsNextChild.dataset.pos);
        var _pos_0 = num_0 > 0 ? sldsNextChild.dataset.pos : 50;
        var _posInit_0 = num_0 - 10;

        gsap.to([sldsNextChild],0,{delay: 0, ease: 'Quad.easeOut', backgroundPosition: _posInit_0 +'% 0px' });
        gsap.to(sldsNextChild,2,{delay: 0, ease: 'Quad.easeOut', backgroundPosition: _pos_0 + '% 0px' });
        //gsap.to(featuredNext,.3,{delay: .3, ease: 'Quad.easeOut', opacity: 1, bottom: '100px' });

      }else{
        sldsActiveChild.firstChild.classList.remove('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
        sldsPrevChild.firstChild.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');


        var num_1 = parseInt(sldsPrevChild.dataset.pos);
        var _pos_1 = num_1 > 0 ? sldsPrevChild.dataset.pos : 50;
        var _posInit_1 = num_1 + 10;

        gsap.to([sldsPrevChild],0,{delay: 0, ease: 'Quad.easeOut', backgroundPosition: _posInit_1 + '% 0px' });
        gsap.to(sldsPrevChild,2,{delay: 0, ease: 'Quad.easeOut', backgroundPosition: _pos_1 +'% 0px' });
      //  gsap.to(featuredPrev,.3,{delay: .3, ease: 'Quad.easeOut', opacity: 1, bottom: '100px' });

      }
      }

    }}
    slidesPerView={1}
    centeredSlides={true}
    spaceBetween={0}
    pagination={{
      type: 'fraction',
    }}
    navigation={true}
    virtual
  >
    {slides.map((slideContent, index) => (
      <SwiperSlide className="swiper-slide-full" key={'s'+index} virtualIndex={index}>
        {slideContent}
      </SwiperSlide>
    ))}
  </Swiper>

    <div id="page-wrapper">




    <div className="container pt-4 p-2 text-center">
    <ScrollAnimation animateIn="animate__animated animate__fadeInUp animate__delay-.3s">
    <h1 className="titling">PRIVATE PARTIES</h1>
    <p className="text-center p-2"> <a className="text-center p-2 font-lm" href="/events/events-at-lilley-mansion/"> HOUSE EVENTS</a> | <a className="text-center p-2 font-lm" href="/events/weddings/">WEDDINGS</a></p>

    </ScrollAnimation>
    </div>




  {/*
    <div className="imageFullWidth">
  { isMobile && data.photos.edges.length > 1 ?

      <GatsbyImage
       fluid={data.photos.edges[1].node.childImageSharp.fluid}
       alt={data.photos.edges[1].node.base.split("-").join(" ").split(".")[0]}
       image={getImage(data.photos.edges[1].node)}
       />
       :
       <GatsbyImage
        fluid={data.photos.edges[0].node.childImageSharp.fluid}
        alt={data.photos.edges[0].node.base.split("-").join(" ").split(".")[0]}
        image={getImage(data.photos.edges[0].node)}
        />
    }


    </div>
    */}
    <div
    style={{
      margin: `0`,
      padding: `20px`,
      background: `#fff`,
      color:`#111`
    }}
    >
    <ScrollAnimation animateIn="animate__animated animate__fadeIn animate__delay-0s">
    <div className="container pt-4 p-2 text-left" dangerouslySetInnerHTML={{ __html: jsonContent }} />

    <div className="text-center">
    <a className="btn btn-secondary_blue btn-small btn-action m-1" href="mailto:cassie@lilleymansion.com?subject=Private party at Lilley Mansion"><small>Inquire now</small></a>
    </div>

    </ScrollAnimation>
    </div>

    </div>
    </div>
  )}
      </ThemeContext.Consumer>

  )
}

WeddingsPage.Layout = Layout

export default WeddingsPage




export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }

    photos: allFile(
          sort: { fields: base, order: ASC }
          filter: {relativeDirectory: {eq: "home"}}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  height: 1200
                  quality: 80
                  formats: AUTO
                  transformOptions: {fit: COVER, cropFocus: ATTENTION}

                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
  }
`
